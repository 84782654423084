import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import SectionMedia from '../SectionMedia';
import ButtonEditor from '../../ButtonEditor/index';

import styles from './styles.module.css';

class StoryLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const imageIndex = props.section.data.findIndex(elem => elem.type === 'IMAGES/IMAGE');

    const layout = props.section.styles.layout ? props.section.styles.layout : 'left';
    const full = props.section.styles.full === true;

    this.state = {
      layout,
      full,
      imageIndex,
    };

  }

  render() {

    const isFull = this.state.layout === 'fullLeft' || this.state.layout === 'fullRight';

    let imageWrapper = 'imageWrapper';
    let sectionMediaWrapper = 'imageContent';
    if (isFull === true) {

      imageWrapper = 'imageWrapperFull';
      sectionMediaWrapper = 'imageContent4';

    } else if (this.props.section.data[this.state.imageIndex].content.icon) {

      imageWrapper = '';
      sectionMediaWrapper = '';

    }

    let leftWrapper = 'storyLeftWrapper';
    let rightWrapper = this.props.section.data[this.state.imageIndex].content.icon ? '' : styles.storyRightWrapperCenter;
    let contentWrapper = '';
    let headingStyle = 'storyHeader';
    let paragraphStyle = '';
    let btnStyle = 'storyBtnWrapper';
    let colLeft;
    let colRight;
    let rowWrapper = '';

    if (this.state.layout === 'left') {

      colLeft = 'col-12 col-md-7 second';
      colRight = 'col-12 col-md-5 first';

    } else if (this.state.layout === 'center') {

      leftWrapper = 'storyLeftWrapperCenter';
      headingStyle = 'storyHeaderCenter';
      paragraphStyle = 'storyParagraphCenter';
      btnStyle = 'storyBtnWrapperCenter';
      colLeft = 'col-12 col-md-8';
      colRight = 'col-12 col-md-5';
      rowWrapper = styles.storyRowWrapperCenter;

    } else if (this.state.layout === 'right') {

      colLeft = 'col-12 col-md-7';
      colRight = 'col-12 col-md-5';
      rowWrapper = styles.storyRowWrapper;

    } else if (this.state.layout === 'fullLeft') {

      colLeft = 'col-12 col-md-6';
      colRight = 'col-12 col-md-6';
      leftWrapper = 'storyWrapperFullLeft';
      rightWrapper = 'storyWrapperFull';
      contentWrapper = 'contentWrapper';

    } else if (this.state.layout === 'fullRight') {

      colLeft = 'col-12 col-md-6';
      colRight = 'col-12 col-md-6';
      leftWrapper = 'storyWrapperFullLeft';
      rightWrapper = 'storyWrapperFull';
      rowWrapper = styles.storyRowWrapper;
      contentWrapper = 'contentWrapper';

    }

    let firstActive = false;
    let secondActive = false;
    const left = [];
    let image;
    this.props.section.data.map((item, index) => {

      if (item.type !== 'IMAGES/IMAGE' && item.active) {

        let result;
        if (item.type === 'BUTTONS/BUTTON_SECONDARY') {

          result = (
            <div
              key={`${this.props.section._id}_layout_${index}`}
              className={styles[btnStyle]}
            >
              <ButtonEditor
                buttons={this.props.buttons}
                themeData={this.props.themeData}
                button="Secondary"
                data={item.content}
                pagePathList={this.props.pagePathList}
                articlePathList={this.props.articlePathList}
                filePathList={this.props.filePathList}
              />
            </div>
          );

        } else {

          let text;
          let type;
          if (item.type.startsWith('HEADINGS')) {

            type = 'heading';
            if (item.type === 'HEADINGS/HEADING-TWO') {

              text = `<h2>${item.text}</h2>`;
              firstActive = true;

            } else if (item.type === 'HEADINGS/HEADING-FOUR') {

              text = `<h4>${item.text}</h4>`;
              secondActive = true;

            }

          } else if (item.type.startsWith('PARAGRAPH')) {

            text = `<span>${item.text}</span>`;
            type = 'paragraph';

          }

          if (text !== undefined) {

            result = (
              <div
                key={`${this.props.section._id}_layout_${index}`}
                className={`${type === 'heading' ? styles[headingStyle] : styles[paragraphStyle]} ${isFull ? 'storyFull' : ''}`}
              >
                {HTMLParser(text)}
              </div>
            );

          }

        }

        left.push(result);

      } else if (item.active && item.type === 'IMAGES/IMAGE') {

        const move = item && item.content && item.content.transform
          && (item.content.transform.offsetX !== 0 || item.content.transform.offsetY !== 0);

        image = (
          <div className={styles[imageWrapper]}>
            <SectionMedia
              mediaType={item.content.icon ? 'ICON' : 'IMAGE'}
              wrapperStyle={move ? 'imageContentMove' : sectionMediaWrapper}
              elementStyle={move ? '' : isFull ? 'imageFull' : 'storyImage'}
              iconStyle=""
              src={item.content.src}
              alt={item.content.alt}
              data={item.content}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              useTransforms={!this.state.layout.startsWith('full')}
              colors={this.props.themeData.colors}
            />
          </div>
        );

      }

      return null;

    });

    if (isFull === true && firstActive && secondActive) {

      const tmp = left[1];
      left[1] = left[0];
      left[0] = tmp;

    }

    return (
      <div className={isFull && this.state.full ? undefined : 'container'}>
        <div className={`row ${rowWrapper}`} style={{ margin: 0 }}>
          <div className={`${colRight} ${styles[rightWrapper]}`}>
            { image }
          </div>
          <div className={`${colLeft} ${styles[leftWrapper]}`}>
            <div className={styles[contentWrapper]}>
              { left }
            </div>
          </div>
        </div>
      </div>
    );

  }

}

StoryLayout.propTypes = {
  themeData: PropTypes.shape({}),
  section: PropTypes.shape({}),
};

export default StoryLayout;
